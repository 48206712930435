import React, { useEffect, useState } from "react";
import "../LoginPage/LoginPage.scss";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  ApolloLink,
  split,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { Query, Subscription } from "@apollo/react-components";
import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { Layout, Modal } from "antd/es";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { SidebarLogo } from "../Logo/SidebarLogo";
import { MobileLogo } from "../Logo/MobileLogo";
import Menu from "../Menu";
import Loading from "../Loading";
import NotificationDrawer from "../NotificationDrawer";
import FirmListing from "../FirmListing";
import FirmDetails from "../FirmDetails";
import UserListing from "../UserListing";
import UserDetails from "../UserDetails";
import TaskListing from "../TaskListing";
import TaskDetails from "../TaskDetails";
import PageNotFound from "../PageNotFound";
import { ReactComponent as IconNotifications } from "../../assets/images/icon-notifications.svg";
import introspectionQueryResultData from "../../graphql/fragmentTypes.json";
import { BP_LARGE } from "../../consts";
import "./App.scss";
import * as Sentry from "@sentry/browser";
import env from "@beam-australia/react-env";
import { NEW_NOTIFICATION } from "../../graphql/notifications/new-notification";
import { LIST_NOTIFICATIONS } from "../../graphql/notifications/list-notifications";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import jwt_decode from "jwt-decode";
import { Button, Form } from "antd/es";
import { ReactComponent as Logo } from "../../assets/images/transparently-admin-logo-stacked.svg";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const authLink = setContext(async (_, { headers }) => {
  const token = await localStorage.getItem("authToken");
  return {
    headers: {
      ...headers,
      accept: "application/json",
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// If user is unauthenticated because their token has expired, log them out
let logoutGlobal;
const errorLink = onError((error) => {
  if (error?.networkError || error?.graphQLErrors) {
    if (
      (error?.networkError?.message === "Unauthenticated" ||
        !!error?.graphQLErrors?.find(
          (er) =>
            er?.message === "Unauthorized" || er?.message === "Unauthenticated"
        )) &&
      logoutGlobal
    ) {
      logoutGlobal(true);
    }
  }
});

const customFetch = (_, options) => {
  if (options.body instanceof FormData) return fetch(env("API_URL"), options);
  return fetch(env("API_URL"), options);
};

const definitionLink = ({ query }) => {
  const definition = getMainDefinition(query);
  return (
    definition.kind === "OperationDefinition" &&
    definition.operation === "subscription"
  );
};

const isObject = (node) => typeof node === "object" && node !== null;

const hasFiles = (node, found = []) => {
  Object.keys(node).forEach((key) => {
    if (!isObject(node[key]) || found.length > 0) {
      return;
    }

    if (
      (typeof File !== "undefined" && node[key] instanceof File) ||
      (typeof Blob !== "undefined" && node[key] instanceof Blob)
    ) {
      found.push(node[key]);
      return;
    }

    hasFiles(node[key], found);
  });

  return found.length > 0;
};

const wsLink = new WebSocketLink({
  uri: env("WS_URL"),
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      const token = await localStorage.getItem("authToken");

      return {
        authToken: token,
      };
    },
  },
});

const uploadLink = split(
  ({ variables }) => hasFiles(variables),
  createUploadLink({
    uri: env("API_URL"),
    fetch: customFetch,
  }),
  new BatchHttpLink({
    uri: env("API_URL"),
    fetch: customFetch,
  })
);

const App = () => {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    user,
    getAccessTokenSilently,
  } = useAuth0();
  const [token, setToken] = useState(null);

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [notificationDrawerVisible, setNotificationDrawerVisible] =
    useState(false);
  const { Header, Content, Sider } = Layout;

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= BP_LARGE) {
        setMobileMenuActive(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const storeToken = async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          clientId: `${env("AUTH0_CLIENT_ID")}`,
          audience: `${env("AUTH0_AUDIENCE")}`,
          scope: "openid profile offline_access",
        },
      });

      const decodedToken = jwt_decode(accessToken);

      if (decodedToken.permissions.includes("access:administration")) {
        setToken(accessToken);
        localStorage.setItem("authToken", accessToken);
      } else {
        logout({ logoutParams: { returnTo: window.location.origin } });
      }
    };
    if (isAuthenticated) {
      storeToken();

      Sentry.setUser({
        id: user.sid,
        email: user.email,
        name: `${user.given_name} ${user.family_name}`,
      });
    }
  }, [isAuthenticated, loginWithRedirect, getAccessTokenSilently]);

  const client = new ApolloClient({
    link: ApolloLink.from([
      errorLink,
      authLink,
      split(definitionLink, wsLink, uploadLink),
    ]),
    cache: new InMemoryCache({
      fragmentMatcher,
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
      query: {
        fetchPolicy: "cache-and-network",
      },
    },
  });

  const handleLogout = (forceLogout) => {
    if (forceLogout) {
      logout({ logoutParams: { returnTo: window.location.origin } });
      localStorage.removeItem("authToken");
      Sentry.configureScope((scope) => scope.clear());
    } else {
      Modal.confirm({
        title: "Logout",
        icon: <ExclamationCircleOutlined />,
        content: `Are you sure you want to logout?`,
        centered: true,
        okText: "Logout",
        className: "logout-modal",
        cancelText: "Cancel",
        onOk: () => handleLogout(true),
      });
    }
  };

  const handleMobileMenuClick = () => {
    if (window.innerWidth < BP_LARGE) {
      setMobileMenuActive((prevState) => !prevState);
    }
  };

  const handleNotificationDrawerVisible = () => {
    setNotificationDrawerVisible((prevState) => !prevState);
  };

  return (
    <ApolloProvider client={client}>
      <BrowserRouter
        getUserConfirmation={(message, callback) => {
          Modal.confirm({
            title: "Unsaved changes",
            icon: <ExclamationCircleOutlined />,
            content: message,
            centered: true,
            okText: "Yes, leave page",
            cancelText: "No, go back",
            onOk: () => callback(true),
            onCancel: () => callback(false),
          });
        }}
      >
        {isAuthenticated && token ? (
          <Query
            query={LIST_NOTIFICATIONS}
            variables={{
              page: 1,
            }}
          >
            {({ loading, data: notificationsData }) => {
              if (loading) {
                return <Loading />;
              }

              return (
                <>
                  <Subscription
                    subscription={NEW_NOTIFICATION}
                    variables={{}}
                    onSubscriptionData={({ client, subscriptionData }) => {
                      const query = client.readQuery({
                        query: LIST_NOTIFICATIONS,
                        variables: {
                          page: 1,
                        },
                      });

                      const notifications = [
                        subscriptionData.data.newNotification,
                        ...query.notifications.notifications,
                      ];

                      const notificationsDeDuped = Array.from(
                        new Set(notifications.map((a) => a.id))
                      ).map((id) => {
                        return notifications.find((a) => a.id === id);
                      });

                      const notificationsData = {
                        notifications: {
                          ...query.notifications,
                          totalUnread: query.notifications.totalUnread + 1,
                          notifications: notificationsDeDuped,
                        },
                      };

                      client.writeQuery({
                        query: LIST_NOTIFICATIONS,
                        data: notificationsData,
                        variables: {
                          page: 1,
                        },
                      });
                    }}
                  >
                    {({ data: newNotificationData }) => {
                      return (
                        <>
                          <NotificationDrawer
                            open={notificationDrawerVisible}
                            onClose={handleNotificationDrawerVisible}
                            notifications={
                              notificationsData?.notifications.notifications
                            }
                            hasMorePages={
                              notificationsData?.notifications.hasMorePages
                            }
                            totalUnread={
                              notificationsData?.notifications.totalUnread
                            }
                          />
                          <Layout>
                            <Sider
                              className={`${mobileMenuActive && "mobile-menu-active"} menu-sidebar`}
                              id="menu-sidebar"
                            >
                              <SidebarLogo />
                              <Menu
                                handleLogout={handleLogout}
                                handleMobileMenuClick={handleMobileMenuClick}
                                handleNotificationDrawerVisible={
                                  handleNotificationDrawerVisible
                                }
                                totalUnreadNotifications={
                                  notificationsData?.notifications.totalUnread
                                }
                              />
                            </Sider>
                            <div className="main-content">
                              <div className="component-container">
                                <Layout
                                  className={`site-layout ${mobileMenuActive && "mobile-menu-active"}`}
                                >
                                  <Header className="site-layout-background site-header">
                                    <MobileLogo />
                                    <div
                                      className={`site-header__notification ${notificationsData?.notifications.totalUnread > 0 ? "site-header__notification--unread" : ""}`}
                                      onClick={handleNotificationDrawerVisible}
                                    >
                                      <IconNotifications />
                                    </div>
                                    <div
                                      className={`burger ${mobileMenuActive ? "active" : "inactive"}`}
                                      onClick={handleMobileMenuClick}
                                    >
                                      <span></span>
                                    </div>
                                  </Header>
                                  <div className="table-content">
                                    <Content>
                                      <Switch>
                                        <Route
                                          path="/firms/:id"
                                          component={FirmDetails}
                                        />
                                        <Route
                                          path="/firms"
                                          component={FirmListing}
                                        />
                                        <Route
                                          path="/users/:id"
                                          component={UserDetails}
                                        />
                                        <Route
                                          path="/users"
                                          component={UserListing}
                                        />
                                        <Route
                                          path="/tasks/:id"
                                          component={TaskDetails}
                                        />
                                        <Route
                                          path="/tasks"
                                          component={TaskListing}
                                        />
                                        <Route
                                          exact
                                          path="/"
                                          component={FirmListing}
                                        />
                                        <Redirect to="/" from="/login" />
                                        <Route
                                          path="*"
                                          component={PageNotFound}
                                        />
                                      </Switch>
                                    </Content>
                                  </div>
                                </Layout>
                              </div>
                            </div>
                          </Layout>
                        </>
                      );
                    }}
                  </Subscription>
                </>
              );
            }}
          </Query>
        ) : (
          <div className="login-page">
            <Logo className="login-page__logo" />
            <div id="login-form-container">
              <Form
                requiredMark={false}
                {...layout}
                name="login-form"
                id="login-form"
                layout="vertical"
                className="login-page__form"
                onFinish={(values) => {
                  loginWithRedirect();
                }}
              >
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-page__form-button"
                    loading={isLoading}
                  >
                    {isLoading ? "logging in" : "Sign in"}
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <div className="login-page__gradient-bar"></div>
          </div>
        )}
      </BrowserRouter>
    </ApolloProvider>
  );
};

const Root = () => (
  <Auth0Provider
    domain={env("AUTH0_DOMAIN")}
    clientId={env("AUTH0_CLIENT_ID")}
    useRefreshTokens
    authorizationParams={{
      audience: env("AUTH0_AUDIENCE"),
      redirect_uri: window.location.origin,
    }}
    scope="openid profile offline_access"
    cacheLocation="localstorage"
  >
    <App />
  </Auth0Provider>
);

export default Root;


import React, { PureComponent } from 'react';
import Loading from '../Loading';
import { Mutation, Query } from '@apollo/react-components';
import { Button, Form, Input, message } from 'antd/es';
import { PlusCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { GET_TASK } from '../../graphql/tasks/get-task';
import { GET_QUESTION } from '../../graphql/tasks/questions/get-question';
import { PREPARE_QUESTION } from '../../graphql/tasks/questions/prepare-question';
import { EDIT_QUESTION } from '../../graphql/tasks/questions/edit-question';
import TaskQuestionPart from '../TaskDetails/TaskQuestionPart';
import SingleLineTextPart from './question-parts/SingleLineTextPart';
import MultiLineTextPart from './question-parts/MultiLineTextPart';
import DatePart from './question-parts/DatePart';
import BooleanPart from './question-parts/BooleanPart';
import AddressPart from './question-parts/AddressPart';
import NumberPart from './question-parts/NumberPart';
import PhonePart from './question-parts/PhonePart';
import PercentPart from './question-parts/PercentPart';
import MoneyPart from './question-parts/MoneyPart';
import EmailPart from './question-parts/EmailPart';
import FilePart from './question-parts/FilePart';
import ChoicePart from './question-parts/ChoicePart';
import ListPart from './question-parts/ListPart';
import './Forms.scss';

class TaskQuestionForm extends PureComponent {
  render() {
    return (
      <Query
        query={GET_QUESTION}
        variables={{
          questionId: this.props.questionId,
        }}
        skip={!this.props.questionId}
      >
        {({ data: questionData, loading: questionLoading }) =>
          questionLoading && this.props.questionId ? (
            <Loading small />
          ) : (
            <Mutation
              mutation={this.props.questionId ? EDIT_QUESTION : PREPARE_QUESTION}
              refetchQueries={[{ query: GET_TASK, variables: { taskId: this.props.taskId } }]}
              onError={(data) => {
                message.error(`Sorry, an error occurred`, 4);
              }}
              onCompleted={(data) => {
                message.success(
                  `The question has been ${
                    this.props.questionId ? 'edited' : 'added'
                  } successfully`,
                  4
                );
                /* Close modal */
                this.props.closeQuestionModal();
              }}
            >
              {(TaskQuestion, { loading, error, data }) => (
                <Form
                  className="task-question-form"
                  layout="vertical"
                  initialValues={
                    this.props.questionId
                      ? {
                          text: questionData?.question?.text,
                          description: questionData?.question?.description,
                          officialAdvice: questionData?.question?.officialAdvice,
                          parts: JSON.parse(
                            JSON.stringify(
                              questionData?.question.parts
                                .map((part) => {
                                  if (part.type === 'LIST') {
                                    return {
                                      ...part,
                                      ...(part.configuration && {
                                        configuration: {
                                          ...part.configuration,
                                          attributes: part.configuration.attributes
                                            .slice()
                                            .sort((a, b) => (a.ordering > b.ordering ? 1 : -1)),
                                        },
                                      }),
                                    };
                                  }
                                  return part;
                                })
                                .sort((a, b) => (a.ordering > b.ordering ? 1 : -1)),
                              (k, v) => (k === '__typename' || k === 'ordering' ? undefined : v)
                            )
                          ),
                        }
                      : {
                          parts: [{}],
                        }
                  }
                  onFinish={(values) => {
                    if (this.props.questionId) {
                      TaskQuestion({
                        variables: {
                          input: {
                            questionId: this.props.questionId,
                            text: values.text,
                            description: values.description,
                            officialAdvice: values.officialAdvice,
                            parts: values.parts,
                          },
                        },
                      });
                    } else {
                      TaskQuestion({
                        variables: {
                          input: {
                            taskId: this.props.taskId,
                            sectionId: this.props.sectionId,
                            text: values.text,
                            description: values.description,
                            officialAdvice: values.officialAdvice,
                            parts: values.parts,
                          },
                        },
                      });
                    }
                  }}
                >
                  <div className="ant-row ant-form-item">
                    <div className="form-field-highlight">
                      <Form.Item
                        name={'text'}
                        label="Question text"
                        validateTrigger={['onBlur', 'onChange']}
                        rules={[
                          {
                            validator: (_, value) => {
                              if (!value) {
                                return Promise.reject('Question text is required');
                              }

                              if (value.length > 15000) {
                                return Promise.reject(
                                  'Question text must be between 1 and 15,000 characters'
                                );
                              }

                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input placeholder="Type question text e.g. 'Contact details'" />
                      </Form.Item>
                      <Form.Item
                        name={'description'}
                        label="Question description"
                        validateTrigger={['onBlur', 'onChange']}
                        rules={[
                          {
                            validator: (_, value) => {
                              if (value && value.length > 15000) {
                                return Promise.reject(
                                  'Question description must be between 1 and 15,000 characters'
                                );
                              }

                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input.TextArea
                          placeholder="Provide the description for the question"
                          rows={5}
                        />
                      </Form.Item>
                      <Form.Item
                        name={'officialAdvice'}
                        label="Official advice for the question"
                        validateTrigger={['onBlur', 'onChange']}
                        rules={[
                          {
                            validator: (_, value) => {
                              if (value && value.length > 5000) {
                                return Promise.reject(
                                  'Official advice must be between 1 and 5000 characters'
                                );
                              }

                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input.TextArea
                          placeholder="Provide the official advice for the question"
                          rows={5}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <Form.List name="parts">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          {fields.map((field, index) => (
                            <Form.Item key={field.key} shouldUpdate>
                              {(form) => {
                                return (
                                  <>
                                    <div className="form-field-highlight form-field-highlight--no-label">
                                      <div className="u-flex">
                                        <TaskQuestionPart
                                          field={field}
                                          partType={form.getFieldValue([
                                            'parts',
                                            field.name,
                                            'type',
                                          ])}
                                        />
                                        <Button
                                          className="task-question-part__remove-btn"
                                          onClick={() => {
                                            remove(field.name);
                                          }}
                                        >
                                          <CloseOutlined />
                                        </Button>
                                      </div>

                                      {form.getFieldValue(['parts', field.name, 'type']) ===
                                        'STRING' && (
                                        <SingleLineTextPart
                                          field={field}
                                          form={form}
                                          questionId={this.props.questionId}
                                        />
                                      )}

                                      {form.getFieldValue(['parts', field.name, 'type']) ===
                                        'TEXT' && (
                                        <MultiLineTextPart
                                          field={field}
                                          form={form}
                                          questionId={this.props.questionId}
                                        />
                                      )}

                                      {form.getFieldValue(['parts', field.name, 'type']) ===
                                        'DATE' && (
                                        <DatePart
                                          field={field}
                                          form={form}
                                          questionId={this.props.questionId}
                                        />
                                      )}

                                      {form.getFieldValue(['parts', field.name, 'type']) ===
                                        'BOOLEAN' && (
                                        <BooleanPart
                                          field={field}
                                          form={form}
                                          questionId={this.props.questionId}
                                        />
                                      )}

                                      {form.getFieldValue(['parts', field.name, 'type']) ===
                                        'ADDRESS' && (
                                        <AddressPart
                                          field={field}
                                          form={form}
                                          questionId={this.props.questionId}
                                        />
                                      )}

                                      {form.getFieldValue(['parts', field.name, 'type']) ===
                                        'NUMBER' && (
                                        <NumberPart
                                          field={field}
                                          form={form}
                                          questionId={this.props.questionId}
                                        />
                                      )}

                                      {form.getFieldValue(['parts', field.name, 'type']) ===
                                        'PERCENT' && (
                                        <PercentPart
                                          field={field}
                                          form={form}
                                          questionId={this.props.questionId}
                                        />
                                      )}

                                      {form.getFieldValue(['parts', field.name, 'type']) ===
                                        'PHONE' && (
                                        <PhonePart
                                          field={field}
                                          form={form}
                                          questionId={this.props.questionId}
                                        />
                                      )}

                                      {form.getFieldValue(['parts', field.name, 'type']) ===
                                        'MONEY' && (
                                        <MoneyPart
                                          field={field}
                                          form={form}
                                          questionId={this.props.questionId}
                                        />
                                      )}

                                      {form.getFieldValue(['parts', field.name, 'type']) ===
                                        'EMAIL' && (
                                        <EmailPart
                                          field={field}
                                          form={form}
                                          questionId={this.props.questionId}
                                        />
                                      )}

                                      {form.getFieldValue(['parts', field.name, 'type']) ===
                                        'FILE' && (
                                        <FilePart
                                          field={field}
                                          form={form}
                                          questionId={this.props.questionId}
                                        />
                                      )}

                                      {form.getFieldValue(['parts', field.name, 'type']) ===
                                        'CHOICE' && (
                                        <ChoicePart
                                          field={field}
                                          form={form}
                                          questionId={this.props.questionId}
                                        />
                                      )}

                                      {form.getFieldValue(['parts', field.name, 'type']) ===
                                        'LIST' && (
                                        <ListPart
                                          field={field}
                                          form={form}
                                          questionId={this.props.questionId}
                                        />
                                      )}
                                    </div>
                                  </>
                                );
                              }}
                            </Form.Item>
                          ))}
                          <Form.Item>
                            <Button
                              type="link"
                              onClick={() => {
                                add();
                              }}
                              className="add-question-part-button u-flex-center-both"
                            >
                              <div className="u-flex-center-both p-10">
                                <PlusCircleOutlined className="add-question-part-button__icon" />
                                <span>Add question part</span>
                              </div>
                            </Button>
                          </Form.Item>
                        </>
                      );
                    }}
                  </Form.List>

                  <Form.Item className="u-text-center">
                    <Button type="primary" htmlType="submit" loading={loading}>
                      {this.props.questionId ? 'Edit ' : 'Add '}question
                    </Button>
                    <Button onClick={this.props.closeQuestionModal} className="ml-20">
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </Mutation>
          )
        }
      </Query>
    );
  }
}

export default React.memo(TaskQuestionForm);

import React, { PureComponent } from 'react';
import { Form, Input, InputNumber, Checkbox, Button } from 'antd/es';
import { PlusCircleOutlined, CloseOutlined } from '@ant-design/icons';
import './../Forms.scss';
import './ChoicePart.scss';

class ChoicePart extends PureComponent {
  constructor(props) {
    super(props);

    /* Set some form defaults (workaround) */
    const values = props.form.getFieldsValue();
    if (values?.parts) {
      props.form.setFieldsValue({
        ...values,
        parts: values.parts.map((part, index) => {
          if (index === props.field.name && !props.isList) {
            return {
              ...part,
              configuration: {
                ...part.configuration,
                isRequired: true,
                options: [{ value: '' }, { value: '' }],
              },
            };
          }
          return part;
        }),
      });
    }
  }

  render() {
    const noOfOptions = this.props.form.getFieldValue([
      'parts',
      this.props.field.name,
      'configuration',
      'options',
    ]).length;

    return (
      <div className="task-question-part-content">
        <Form.Item
          name={[this.props.field.name, 'label']}
          label="Question part heading / label"
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject('Question part heading / label is required');
                }

                if (value.length > 15000) {
                  return Promise.reject('Must be between 1 and 15,000 characters');
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder="Provide a label for the question part e.g. 'Total salary'" />
        </Form.Item>
        <Form.Item
          name={[this.props.field.name, 'instructions']}
          label="Instructions"
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            {
              validator: (_, value) => {
                if (value && value.length > 15000) {
                  return Promise.reject('Must be between 1 and 15,000 characters');
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input.TextArea
            placeholder="Type the instructions for the question part here e.g. 'You must give one answer'"
            rows={3}
          />
        </Form.Item>
        <Form.Item
          name={[this.props.field.name, 'officialAdvice']}
          label="Official advice for the question part"
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            {
              validator: (_, value) => {
                if (value && value.length > 5000) {
                  return Promise.reject('Must be between 1 and 5000 characters');
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input.TextArea
            placeholder="Provide the official advice for the question part"
            rows={3}
          />
        </Form.Item>
        <Form.List name={[this.props.field.name, 'configuration', 'options']}>
          {(fields, { add, remove }) => {
            return (
              <div>
                <div className="ant-col ant-form-item-label">
                  <label>Options (minimum of 2)</label>
                </div>

                {fields.map((field, index) => (
                  <Form.Item className="mb-0" key={field.key}>
                    <div>
                      <div className="choice-part__option">
                        <Form.Item
                          name={[field.name, 'value']}
                          label={`Option ${index + 1} value`}
                          style={{ width: '100%' }}
                          className="form-field--small-label mb-10"
                          validateTrigger={['onBlur']}
                          rules={[
                            {
                              required: true,
                              message: `Option value ${index + 1} is required`,
                            },
                          ]}
                        >
                          <div className="u-flex-align-center">
                            <Input
                              defaultValue={this.props.form.getFieldValue([
                                'parts',
                                this.props.field.name,
                                'configuration',
                                'options',
                                field.name,
                                'value',
                              ])}
                            />
                            {fields.length > 2 && (
                              <Button
                                type="link"
                                className="remove-choice-option-btn"
                                onClick={() => {
                                  remove(field.name);
                                  this.forceUpdate();
                                }}
                              >
                                <CloseOutlined />
                              </Button>
                            )}
                          </div>
                        </Form.Item>
                        <Form.Item
                          name={[field.name, 'description']}
                          label={`Option ${index + 1} description (optional)`}
                          style={{ width: '100%' }}
                          className="form-field--small-label mb-10"
                        >
                          <div className="u-flex-align-center">
                            <Input.TextArea
                              rows={3}
                              defaultValue={this.props.form.getFieldValue([
                                'parts',
                                this.props.field.name,
                                'configuration',
                                'options',
                                field.name,
                                'description',
                              ])}
                            />
                            {fields.length > 2 && <div style={{ width: 25 }}></div>}
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={() => {
                      add();
                      this.forceUpdate();
                    }}
                    className="add-choice-option-btn"
                  >
                    <div className="u-flex-center-both">
                      <PlusCircleOutlined className="add-choice-option-btn__icon" />
                      <span>Add option</span>
                    </div>
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>

        <Form.Item
          name={[this.props.field.name, 'configuration', 'minimumChoices']}
          label="Required minimum number of options to be selected by the user"
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject('Minimum number of options is required');
                }

                if (!Number.isInteger(value)) {
                  return Promise.reject('Minimum number of options must be an integer');
                }

                if (value > noOfOptions) {
                  return Promise.reject(
                    `Minimum number must be between 1 and the total number of question options`
                  );
                }

                if (
                  this.props.form.getFieldValue([
                    'parts',
                    this.props.field.name,
                    'configuration',
                    'minimumChoices',
                  ]) >
                  this.props.form.getFieldValue([
                    'parts',
                    this.props.field.name,
                    'configuration',
                    'maximumChoices',
                  ])
                ) {
                  return Promise.reject('Minimum choices cannot be more than maximum choices');
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber min={1} max={noOfOptions} placeholder="Minimum" />
        </Form.Item>
        <Form.Item
          name={[this.props.field.name, 'configuration', 'maximumChoices']}
          label="Required maximum number of options to be selected by the user"
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject('Maximum number of options is required');
                }

                if (!Number.isInteger(value)) {
                  return Promise.reject('Maximum number of options must be an integer');
                }

                if (value > noOfOptions) {
                  return Promise.reject(
                    `Maximum number must be between 1 and the total number of question options`
                  );
                }

                if (
                  this.props.form.getFieldValue([
                    'parts',
                    this.props.field.name,
                    'configuration',
                    'maximumChoices',
                  ]) <
                  this.props.form.getFieldValue([
                    'parts',
                    this.props.field.name,
                    'configuration',
                    'minimumChoices',
                  ])
                ) {
                  return Promise.reject('Maximum choices cannot be less than minimum choices');
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber min={1} max={noOfOptions} placeholder="Maximum" />
        </Form.Item>
        <Form.Item
          name={[this.props.field.name, 'configuration', 'isRequired']}
          valuePropName="checked"
        >
          <Checkbox>Is an answer required?</Checkbox>
        </Form.Item>
      </div>
    );
  }
}

export default ChoicePart;

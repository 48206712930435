import React, { PureComponent } from 'react';
import { Form, Input } from 'antd/es';
import './../Forms.scss';

class BooleanPart extends PureComponent {
  render() {
    return (
      <div className="task-question-part-content">
        <Form.Item
          name={[this.props.field.name, 'label']}
          label="Question part heading / label"
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject('Question part heading / label is required');
                }

                if (value.length > 15000) {
                  return Promise.reject('Must be between 1 and 15,000 characters');
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder="Provide a label for the question part e.g. 'First name'" />
        </Form.Item>
        <Form.Item
          name={[this.props.field.name, 'instructions']}
          label="Instructions"
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            {
              validator: (_, value) => {
                if (value && value.length > 15000) {
                  return Promise.reject('Must be between 1 and 15,000 characters');
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input.TextArea
            placeholder="Type the instructions for the question part here e.g. 'You must give one answer'"
            rows={3}
          />
        </Form.Item>
        <Form.Item
          name={[this.props.field.name, 'officialAdvice']}
          label="Official advice for the question part"
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            {
              validator: (_, value) => {
                if (value && value.length > 5000) {
                  return Promise.reject('Must be between 1 and 5000 characters');
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input.TextArea
            placeholder="Provide the official advice for the question part"
            rows={3}
          />
        </Form.Item>
        <Form.Item
          name={[this.props.field.name, 'configuration', 'positiveLabel']}
          label="Positive label"
          validateTrigger={['onBlur']}
          rules={[
            {
              required: true,
              message: 'Positive label is required',
            },
          ]}
        >
          <Input placeholder="e.g. Yes" />
        </Form.Item>
        <Form.Item
          name={[this.props.field.name, 'configuration', 'negativeLabel']}
          label="Negative label"
          validateTrigger={['onBlur']}
          rules={[
            {
              required: true,
              message: 'Negative label is required',
            },
          ]}
        >
          <Input placeholder="e.g. No" />
        </Form.Item>
      </div>
    );
  }
}

export default BooleanPart;

import React, { PureComponent } from 'react';
import { Form, Input, InputNumber, Checkbox, Button, Collapse } from 'antd/es';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import TaskQuestionPart from '../../TaskDetails/TaskQuestionPart';
import SingleLineTextPart from './SingleLineTextPart';
import MultiLineTextPart from './MultiLineTextPart';
import DatePart from './DatePart';
import AddressPart from './AddressPart';
import NumberPart from './NumberPart';
import PercentPart from './PercentPart';
import MoneyPart from './MoneyPart';
import EmailPart from './EmailPart';
import PhonePart from './PhonePart';
import { ReactComponent as IconNewListItem } from '../../../assets/images/icon-new-list-item.svg';
import { ReactComponent as IconSingleLineText } from '../../../assets/images/icon-part-single-line-text.svg';
import { ReactComponent as IconMultiLineText } from '../../../assets/images/icon-part-multi-line-text.svg';
import { ReactComponent as IconDate } from '../../../assets/images/icon-part-date.svg';
import { ReactComponent as IconAddress } from '../../../assets/images/icon-part-address.svg';
import { ReactComponent as IconNumber } from '../../../assets/images/icon-part-number.svg';
import { ReactComponent as IconPercent } from '../../../assets/images/icon-part-percent.svg';
import { ReactComponent as IconMoney } from '../../../assets/images/icon-part-money.svg';
import { ReactComponent as IconEmail } from '../../../assets/images/icon-part-email.svg';
import { ReactComponent as IconPhone } from '../../../assets/images/icon-part-phone.svg';

import './../Forms.scss';
import './ListPart.scss';

class ListPart extends PureComponent {
  constructor(props) {
    super(props);

    /* Set some form defaults (workaround) */
    const values = props.form.getFieldsValue();
    if (values?.parts) {
      props.form.setFieldsValue({
        ...values,
        parts: values.parts.map((part, index) => {
          if (index === props.field.name && !props.isList) {
            return {
              ...part,
              configuration: {
                ...part.configuration,
                isRequired: true,
                attributes: [{}],
              },
            };
          }
          return part;
        }),
      });
    }
  }

  render() {
    const { Panel } = Collapse;

    return (
      <div className="task-question-part-content">
        <Form.Item
          name={[this.props.field.name, 'label']}
          label="Question part heading / label"
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject('Question part heading / label is required');
                }

                if (value.length > 15000) {
                  return Promise.reject('Must be between 1 and 15,000 characters');
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder="Provide a label for the question part e.g. 'Total salary'" />
        </Form.Item>
        <Form.Item
          name={[this.props.field.name, 'instructions']}
          label="Instructions"
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            {
              validator: (_, value) => {
                if (value && value.length > 15000) {
                  return Promise.reject('Must be between 1 and 15,000 characters');
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input.TextArea
            placeholder="Type the instructions for the question part here e.g. 'You must give one answer'"
            rows={3}
          />
        </Form.Item>
        <Form.Item
          name={[this.props.field.name, 'officialAdvice']}
          label="Official advice for the question part"
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            {
              validator: (_, value) => {
                if (value && value.length > 5000) {
                  return Promise.reject('Must be between 1 and 5000 characters');
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input.TextArea
            placeholder="Provide the official advice for the question part"
            rows={3}
          />
        </Form.Item>
        <Form.Item className="mb-0">
          <div className="ant-col ant-form-item-label">
            <label>List items</label>
          </div>
        </Form.Item>
        <Form.List name={[this.props.field.name, 'configuration', 'attributes']}>
          {(fields, { add, remove }) => {
            return (
              <div className="list-items-container">
                {fields.map((field, index) => (
                  <Form.Item className="mb-0" key={field.key} shouldUpdate>
                    {(form) => {
                      return (
                        <div>
                          <Form.Item>
                            <Collapse
                              defaultActiveKey={['1']}
                              bordered={false}
                              expandIconPosition="right"
                            >
                              <Panel
                                header={
                                  <div className="u-flex-align-center">
                                    {form.getFieldValue([
                                      'parts',
                                      this.props.field.name,
                                      'configuration',
                                      'attributes',
                                      field.name,
                                      'type',
                                    ]) === undefined && (
                                      <>
                                        <div className="task-question-part task-question-part--none">
                                          <IconNewListItem className="task-question-part__icon" />
                                        </div>
                                      </>
                                    )}

                                    {form.getFieldValue([
                                      'parts',
                                      this.props.field.name,
                                      'configuration',
                                      'attributes',
                                      field.name,
                                      'type',
                                    ]) === 'STRING' && (
                                      <>
                                        <div className="task-question-part task-question-part--string">
                                          <IconSingleLineText className="task-question-part__icon" />
                                        </div>
                                      </>
                                    )}

                                    {form.getFieldValue([
                                      'parts',
                                      this.props.field.name,
                                      'configuration',
                                      'attributes',
                                      field.name,
                                      'type',
                                    ]) === 'TEXT' && (
                                      <>
                                        <div className="task-question-part task-question-part--text">
                                          <IconMultiLineText className="task-question-part__icon" />
                                        </div>
                                      </>
                                    )}

                                    {form.getFieldValue([
                                      'parts',
                                      this.props.field.name,
                                      'configuration',
                                      'attributes',
                                      field.name,
                                      'type',
                                    ]) === 'DATE' && (
                                      <>
                                        <div className="task-question-part task-question-part--date">
                                          <IconDate className="task-question-part__icon" />
                                        </div>
                                      </>
                                    )}

                                    {form.getFieldValue([
                                      'parts',
                                      this.props.field.name,
                                      'configuration',
                                      'attributes',
                                      field.name,
                                      'type',
                                    ]) === 'ADDRESS' && (
                                      <>
                                        <div className="task-question-part task-question-part--address">
                                          <IconAddress className="task-question-part__icon" />
                                        </div>
                                      </>
                                    )}

                                    {form.getFieldValue([
                                      'parts',
                                      this.props.field.name,
                                      'configuration',
                                      'attributes',
                                      field.name,
                                      'type',
                                    ]) === 'NUMBER' && (
                                      <>
                                        <div className="task-question-part task-question-part--number">
                                          <IconNumber className="task-question-part__icon" />
                                        </div>
                                      </>
                                    )}

                                    {form.getFieldValue([
                                      'parts',
                                      this.props.field.name,
                                      'configuration',
                                      'attributes',
                                      field.name,
                                      'type',
                                    ]) === 'PERCENT' && (
                                      <>
                                        <div className="task-question-part task-question-part--percent">
                                          <IconPercent className="task-question-part__icon" />
                                        </div>
                                      </>
                                    )}

                                    {form.getFieldValue([
                                      'parts',
                                      this.props.field.name,
                                      'configuration',
                                      'attributes',
                                      field.name,
                                      'type',
                                    ]) === 'MONEY' && (
                                      <>
                                        <div className="task-question-part task-question-part--money">
                                          <IconMoney className="task-question-part__icon" />
                                        </div>
                                      </>
                                    )}

                                    {form.getFieldValue([
                                      'parts',
                                      this.props.field.name,
                                      'configuration',
                                      'attributes',
                                      field.name,
                                      'type',
                                    ]) === 'EMAIL' && (
                                      <>
                                        <div className="task-question-part task-question-part--email">
                                          <IconEmail className="task-question-part__icon" />
                                        </div>
                                      </>
                                    )}

                                    {form.getFieldValue([
                                      'parts',
                                      this.props.field.name,
                                      'configuration',
                                      'attributes',
                                      field.name,
                                      'type',
                                    ]) === 'PHONE' && (
                                      <>
                                        <div className="task-question-part task-question-part--phone">
                                          <IconPhone className="task-question-part__icon" />
                                        </div>
                                      </>
                                    )}

                                    <div>
                                      {form.getFieldValue([
                                        'parts',
                                        this.props.field.name,
                                        'configuration',
                                        'attributes',
                                        field.name,
                                        'label',
                                      ]) || 'new list item'}
                                    </div>
                                  </div>
                                }
                                key="1"
                              >
                                <div className="list-items">
                                  <div className="u-flex">
                                    <TaskQuestionPart
                                      field={field}
                                      partType={form.getFieldValue([
                                        'parts',
                                        this.props.field.name,
                                        'configuration',
                                        'attributes',
                                        field.name,
                                        'type',
                                      ])}
                                      isInsideListItem={true}
                                    />
                                    <Button
                                      className="task-question-part__remove-btn"
                                      onClick={() => {
                                        remove(field.name);
                                        this.forceUpdate();
                                      }}
                                    >
                                      <CloseOutlined />
                                    </Button>
                                  </div>

                                  {form.getFieldValue([
                                    'parts',
                                    this.props.field.name,
                                    'configuration',
                                    'attributes',
                                    field.name,
                                    'type',
                                  ]) === 'STRING' && (
                                    <SingleLineTextPart
                                      form={form}
                                      field={field}
                                      parentField={this.props.field}
                                      isList={true}
                                      questionId={this.props.questionId}
                                    />
                                  )}

                                  {form.getFieldValue([
                                    'parts',
                                    this.props.field.name,
                                    'configuration',
                                    'attributes',
                                    field.name,
                                    'type',
                                  ]) === 'TEXT' && (
                                    <MultiLineTextPart
                                      form={form}
                                      field={field}
                                      parentField={this.props.field}
                                      isList={true}
                                      questionId={this.props.questionId}
                                    />
                                  )}

                                  {form.getFieldValue([
                                    'parts',
                                    this.props.field.name,
                                    'configuration',
                                    'attributes',
                                    field.name,
                                    'type',
                                  ]) === 'DATE' && (
                                    <DatePart
                                      form={form}
                                      field={field}
                                      parentField={this.props.field}
                                      isList={true}
                                      questionId={this.props.questionId}
                                    />
                                  )}

                                  {form.getFieldValue([
                                    'parts',
                                    this.props.field.name,
                                    'configuration',
                                    'attributes',
                                    field.name,
                                    'type',
                                  ]) === 'ADDRESS' && (
                                    <AddressPart
                                      form={form}
                                      field={field}
                                      parentField={this.props.field}
                                      isList={true}
                                      questionId={this.props.questionId}
                                    />
                                  )}

                                  {form.getFieldValue([
                                    'parts',
                                    this.props.field.name,
                                    'configuration',
                                    'attributes',
                                    field.name,
                                    'type',
                                  ]) === 'NUMBER' && (
                                    <NumberPart
                                      form={form}
                                      field={field}
                                      parentField={this.props.field}
                                      isList={true}
                                      questionId={this.props.questionId}
                                    />
                                  )}

                                  {form.getFieldValue([
                                    'parts',
                                    this.props.field.name,
                                    'configuration',
                                    'attributes',
                                    field.name,
                                    'type',
                                  ]) === 'PERCENT' && (
                                    <PercentPart
                                      form={form}
                                      field={field}
                                      parentField={this.props.field}
                                      isList={true}
                                      questionId={this.props.questionId}
                                    />
                                  )}

                                  {form.getFieldValue([
                                    'parts',
                                    this.props.field.name,
                                    'configuration',
                                    'attributes',
                                    field.name,
                                    'type',
                                  ]) === 'MONEY' && (
                                    <MoneyPart
                                      form={form}
                                      field={field}
                                      parentField={this.props.field}
                                      isList={true}
                                      questionId={this.props.questionId}
                                    />
                                  )}

                                  {form.getFieldValue([
                                    'parts',
                                    this.props.field.name,
                                    'configuration',
                                    'attributes',
                                    field.name,
                                    'type',
                                  ]) === 'EMAIL' && (
                                    <EmailPart
                                      form={form}
                                      field={field}
                                      parentField={this.props.field}
                                      isList={true}
                                      questionId={this.props.questionId}
                                    />
                                  )}

                                  {form.getFieldValue([
                                    'parts',
                                    this.props.field.name,
                                    'configuration',
                                    'attributes',
                                    field.name,
                                    'type',
                                  ]) === 'PHONE' && (
                                    <PhonePart
                                      form={form}
                                      field={field}
                                      parentField={this.props.field}
                                      isList={true}
                                      questionId={this.props.questionId}
                                    />
                                  )}
                                </div>
                              </Panel>
                            </Collapse>
                          </Form.Item>
                        </div>
                      );
                    }}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="ghost"
                    onClick={() => {
                      add();
                      this.forceUpdate();
                    }}
                    className="list-items__add-btn"
                  >
                    <div className="u-flex-center-both">
                      <PlusOutlined className="list-items__add-btn__icon" />
                      <span>Add item to list</span>
                    </div>
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
        <Form.Item
          name={[this.props.field.name, 'configuration', 'minimumItems']}
          label="Minimum list items"
          wrapperCol={{ span: 12 }}
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            {
              validator: (_, value) => {
                if (value === null || value === '') {
                  return Promise.reject('Minimum list items is required');
                }

                if (value < 0) {
                  return Promise.reject('Minimum list items must be 0 or above');
                }

                if (!Number.isInteger(value)) {
                  return Promise.reject('Minimum list items must be an whole number');
                }

                if (
                  this.props.form.getFieldValue([
                    'parts',
                    this.props.field.name,
                    'configuration',
                    'minimumItems',
                  ]) >
                  this.props.form.getFieldValue([
                    'parts',
                    this.props.field.name,
                    'configuration',
                    'maximumItems',
                  ])
                ) {
                  return Promise.reject(
                    'Minimum list items cannot be more than maximum list items'
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber
            placeholder="Minimum number of list items"
            style={{ width: '100%' }}
            min={0}
          />
        </Form.Item>
        <Form.Item
          name={[this.props.field.name, 'configuration', 'maximumItems']}
          label="Maximum list items"
          wrapperCol={{ span: 12 }}
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            {
              validator: (_, value) => {
                if (value === null || value === '') {
                  return Promise.reject('Maximum list items is required');
                }

                if (!Number.isInteger(value)) {
                  return Promise.reject('Maximum list items must be an whole number');
                }

                if (
                  this.props.form.getFieldValue([
                    'parts',
                    this.props.field.name,
                    'configuration',
                    'maximumItems',
                  ]) <
                  this.props.form.getFieldValue([
                    'parts',
                    this.props.field.name,
                    'configuration',
                    'minimumItems',
                  ])
                ) {
                  return Promise.reject(
                    'Maximum list items cannot be less than minimum list items'
                  );
                }

                if (value < 0) {
                  return Promise.reject('Maximum list items must be 0 or above');
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber
            placeholder="Maximum number of list items"
            style={{ width: '100%' }}
            min={0}
          />
        </Form.Item>
        <Form.Item
          name={[this.props.field.name, 'configuration', 'isRequired']}
          valuePropName="checked"
        >
          <Checkbox>Is an answer required?</Checkbox>
        </Form.Item>
      </div>
    );
  }
}

export default ListPart;
